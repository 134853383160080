import FormComp from '../FormComp';
import styles from './SquareAdmis.module.css';

import axios from 'axios';
import Cookies from "js-cookie"
import { formatCPF } from '../../Funcoes/FormatCPF';
import { toast } from 'react-toastify';
import { formatTelefone } from '../../Funcoes/FormatTelefone';
import { IoCloseSharp } from "react-icons/io5";
import { useEffect, useState } from 'react';

const SquareAdmis = ({ obras, functions, close, getNewValues, objEdit, editing }) => {

    useEffect(() => {

        if( editing && objEdit && objEdit.motivo === 'ADMISSAO' ){

            setFormValues({
                centroCusto: objEdit.cc_destino ? objEdit.cc_destino : '',
                nome: objEdit.nome ? objEdit.nome : '',
                cpf: objEdit.cpf ? objEdit.cpf : '',
                dataNasc: objEdit.data_nasc ? objEdit.data_nasc : '',
                funcao: objEdit.funcao ? objEdit.funcao : '',
                desist: objEdit.desistente ? objEdit.desistente : '',
                prevCheg: objEdit.prev_cheg ? objEdit.prev_cheg : '',
                obs: objEdit.observacoes ? objEdit.observacoes : '',
                telefone: objEdit.telefone ? objEdit.telefone : '',
                estadoFrom: objEdit.estado_origem ? objEdit.estado_origem : '',
                municipFrom: objEdit.municip_origem ? objEdit.municip_origem : '',
                recebCurriculo: objEdit.receb_curriculo ? objEdit.receb_curriculo : '',
                dataContato: objEdit.data_contato ? objEdit.data_contato : '',
                chegada: objEdit.data_chegada ? objEdit.data_chegada : '',
                exameAdm: objEdit.data_exame ? objEdit.data_exame : '',
                docAdm: objEdit.data_doc_adm ? objEdit.data_doc_adm : '',
                docSesmt: objEdit.data_doc_sesmt ? objEdit.data_doc_sesmt : '',
                admissao: objEdit.data_admissao ? objEdit.data_admissao : '',
                cadPortal: objEdit.data_cad_portal ? objEdit.data_cad_portal : '',
                integracao: objEdit.data_integracao ? objEdit.data_integracao : '',
                entradaSite: objEdit.data_entrada_site ? objEdit.data_entrada_site : '',
                numUniforme: objEdit.num_uniforme ? objEdit.num_uniforme : '',
                numCalcado: objEdit.num_calcado ? objEdit.num_calcado : '',
                id: objEdit.id
            })

        }

    }, [ editing, objEdit ])

    const token = Cookies.get('token')

    const estadosBrasil = [
        "AC", "AL", "AP", "AM", "BA", "CE", "ES", "GO", "MA", "MT",
        "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS",
        "RO", "RR", "SC", "SP", "SE", "TO"
    ];

    const [ municipios, setMunicipios ] = useState([])

    const [formValues, setFormValues] = useState({
        centroCusto: "",
        nome: "",
        cpf: "",
        dataNasc: "",
        funcao: "",
        desist: "",
        prevCheg: "",
        obs: "",
        telefone: "",
        estadoFrom: "",
        municipFrom: "",
        recebCurriculo: "",
        dataContato: "",
        chegada: "",
        exameAdm: "",
        docAdm: "",
        docSesmt: "",
        admissao: "",
        cadPortal: "",
        integracao: "",
        entradaSite: "",
        numUniforme: "",
        numCalcado: ""
    })

    const clearValues = () => {

        setFormValues({
            centroCusto: "",
            nome: "",
            cpf: "",
            dataNasc: "",
            funcao: "",
            desist: "",
            prevCheg: "",
            obs: "",
            telefone: "",
            estadoFrom: "",
            municipFrom: "",
            recebCurriculo: "",
            dataContato: "",
            chegada: "",
            exameAdm: "",
            docAdm: "",
            docSesmt: "",
            admissao: "",
            cadPortal: "",
            integracao: "",
            entradaSite: "",
            numUniforme: "",
            numCalcado: ""
        })

    }

    const handleChange = ( value, name ) => {

        setFormValues( prev => ({

            ...prev,
            [name]: value

        }))

    }

    useEffect(() => {

        handleChange( '', 'municipFrom' )

        const searchMunicip = async () => {

            try{

                const { data } = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ formValues.estadoFrom }/municipios`)
                
                setMunicipios( data.map(( obj ) => (
                    obj.nome.toUpperCase()
                )))

            }catch( error ){

                console.log(error)

            }finally{

                editing && handleChange( objEdit.municip_origem, 'municipFrom' )

            }

        }

        if( formValues.estadoFrom ){

            searchMunicip();

        }else{

            setMunicipios([])

        }

    },[ formValues.estadoFrom, editing ])

    const handleSubmit = async (e) => {

        e.preventDefault()

        let endpointApi = editing ? 'https://sistemaintegrado.palmont.com.br/Painel/API/EditAdmissao/' : 'https://sistemaintegrado.palmont.com.br/Painel/API/SaveAdmissao/'
        
        try{

            const { data } = await toast.promise(
                axios.post(
                    endpointApi,
                    formValues,
                    {
                        headers:{
                            'Authorization': `Bearer ${ token }`
                        }
                    }),
                {
                    pending: 'SALVANDO INFORMAÇÕES',
                    success: 'DADOS SALVOS COM SUCESSO',
                    error: 'FALHA AO SALVAR DADOS'
                }
            )

            console.log( data );

            await getNewValues();
            clearValues();
            close();

        }catch( error ){

            console.log( error.message );

        }finally{



        }

    }

    return (
        <div className={styles.container} >
            <div className={styles.header} >
                <div className={styles.closeBtn}
                    onClick={() => {
                        clearValues();
                        close();
                    }}
                >
                    <IoCloseSharp />
                </div>
            </div>
            <div className={styles.formContainer} >
                <form>
                    <FormComp
                        title="CENTRO CUSTO"
                        type="select"
                        options={obras}
                        valor={ formValues.centroCusto }
                        changeFunc={ (e) => handleChange( e.target.value, 'centroCusto' ) }
                    />
                    <FormComp
                        title="NOME"
                        type="text"
                        valor={ formValues.nome }
                        changeFunc={ (e) => { 

                            let value = e.target.value;
                            value = value.toUpperCase() 

                            handleChange( value, 'nome' )
                        }}
                    />
                    <FormComp
                        title="CPF"
                        type="text"
                        placeholder="Ex: 123.456.789-00"
                        valor={ formatCPF( formValues.cpf ) }
                        changeFunc={ (e) => {

                            let value = e.target.value;
                            value = value.replace(/\D/g, '');

                            handleChange( value, 'cpf' )
                        }}
                    />
                    <FormComp
                        title="DATA DE NASCIMENTO"
                        type="date"
                        valor={ formValues.dataNasc }
                        changeFunc={ (e) => handleChange( e.target.value, 'dataNasc' ) }
                    />
                    <FormComp
                        title="FUNÇÃO"
                        type="select"
                        options={functions}
                        valor={ formValues.funcao }
                        changeFunc={ (e) => handleChange( e.target.value, 'funcao' ) }
                    />
                    <FormComp
                        title="DESISTENCIA"
                        type="select"
                        options={["SIM"]}
                        valor={ formValues.desist }
                        changeFunc={ (e) => handleChange( e.target.value, 'desist' ) }
                    />
                    <FormComp
                        title="PREVISÃO DE CHEGADA"
                        type="date"
                        valor={ formValues.prevCheg }
                        changeFunc={ (e) => handleChange( e.target.value, 'prevCheg' ) }
                    />
                    <FormComp
                        title="OBSERVAÇÕES"
                        type="textarea"
                        valor={ formValues.obs }
                        changeFunc={ (e) => handleChange( e.target.value, 'obs' ) }
                    />
                    <FormComp
                        title="TELEFONE"
                        type="text"
                        placeholder="Ex: (11) 99999-9999"
                        valor={ formatTelefone( formValues.telefone ) }
                        changeFunc={ (e) => { 

                            let value = e.target.value
                            value = value.replace(/\D/g, '');

                            handleChange( value, 'telefone' )
                        }}
                    />
                    <FormComp
                        title="ESTADO DE ORIGEM"
                        type="select"
                        options={estadosBrasil}
                        valor={ formValues.estadoFrom }
                        changeFunc={ (e) => handleChange( e.target.value, 'estadoFrom' ) }
                    />
                    <FormComp
                        title="MUNICÍPIO DE ORIGEM"
                        type="select"
                        options={ municipios }
                        valor={ formValues.municipFrom }
                        changeFunc={ (e) => handleChange( e.target.value,'municipFrom' ) }
                    />
                    <FormComp
                        title="RECEBIMENTO DO CURRICULO"
                        type="date"
                        valor={ formValues.recebCurriculo }
                        changeFunc={ (e) => handleChange( e.target.value,'recebCurriculo' ) }
                    />
                    <FormComp
                        title="DATA CONTATO"
                        type="date"
                        valor={ formValues.dataContato }
                        changeFunc={ (e) => handleChange( e.target.value,'dataContato' ) }
                    />
                    <FormComp
                        title="CHEGADA"
                        type="date"
                        valor={ formValues.chegada }
                        changeFunc={ (e) => handleChange( e.target.value,'chegada' ) }
                    />
                    <FormComp
                        title="EXAME ADMISSIONAL"
                        type="date"
                        valor={ formValues.exameAdm }
                        changeFunc={ (e) => handleChange( e.target.value,'exameAdm' ) }
                    />

                    <FormComp
                        title="DOC. ADMISSÃO"
                        type="date"
                        valor={ formValues.docAdm }
                        changeFunc={ (e) => handleChange( e.target.value,'docAdm' ) }
                    />

                    <FormComp
                        title="DOC. SESMT"
                        type="date"
                        valor={ formValues.docSesmt }
                        changeFunc={ (e) => handleChange( e.target.value,'docSesmt' ) }
                    />
                    <FormComp
                        title="ADMISSÃO"
                        type="date"
                        valor={ formValues.admissao }
                        changeFunc={ (e) => handleChange( e.target.value,'admissao' ) }
                    />
                    <FormComp
                        title="CADASTRO PORTAL"
                        type="date"
                        valor={ formValues.cadPortal }
                        changeFunc={ (e) => handleChange( e.target.value,'cadPortal' ) }
                    />
                    <FormComp
                        title="INTEGRAÇÃO"
                        type="date"
                        valor={ formValues.integracao }
                        changeFunc={ (e) => handleChange( e.target.value,'integracao' ) }
                    />
                    <FormComp
                        title="ENTRADA NO SITE"
                        type="date"
                        valor={ formValues.entradaSite }
                        changeFunc={ (e) => handleChange( e.target.value,'entradaSite' ) }
                    />
                    <FormComp
                        title="NÚMERO UNIFORME"
                        type="select"
                        options={[
                            "P", "M", "G", "GG", "EXG"
                        ]}
                        valor={ formValues.numUniforme }
                        changeFunc={ (e) => handleChange( e.target.value,'numUniforme' ) }
                    />
                    <FormComp
                        title="NÚMERO CALÇADO"
                        type="number"
                        valor={ formValues.numCalcado }
                        changeFunc={ (e) => handleChange( e.target.value,'numCalcado' ) }
                    />

                    <button
                        type="submit"
                        onClick={ handleSubmit }
                    >SALVAR</button>

                </form>

            </div>

        </div>

    )

}

export default SquareAdmis;