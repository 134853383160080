import { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import style from './BodyAfast.module.css';

const BodyAfast = ({ dados, onFilter, funcoes, obras, motivos, lineClick, loading, onSort, onSortByDate }) => {

    const ValueContainer = ({ children, ...props }) => {
        let [values, input] = children;
        if (Array.isArray(values)) {
            const plural = values.length === 1 ? "" : "S";
            values = `${values.length} SELECIONADO${plural}`;
        }
        return (
            <components.ValueContainer {...props}>
                {values}
                {input}
            </components.ValueContainer>
        );
    };

    const [linhas, setLinhas] = useState(100);

    const [selectedName, setSelectedName] = useState('');
    const [selectedMat, setSelectedMat] = useState('');
    const [selectedFuncoes, setSelectedFuncoes] = useState([]);
    const [selectedObras, setSelectedObras] = useState([]);
    const [selectedMotivos, setSelectedMotivos] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);

    const [dataInicio, setDataInicio] = useState('');
    const [dataFinal, setDataFinal] = useState('');

    useEffect(() => {

        onFilter(selectedName, selectedMat, selectedFuncoes, selectedObras, selectedMotivos, selectedStatus, dataInicio, dataFinal)

    }, [selectedName, selectedMat, selectedFuncoes, selectedObras, selectedMotivos, selectedStatus, dataInicio, dataFinal]);

    const handleChange = (selected, name) => {

        const valor = selected.map(obj => obj.value)
        console.log(valor);

        switch (name) {

            case 'funcao':
                setSelectedFuncoes(valor);
                break;
            case 'obra':
                setSelectedObras(valor);
                break;
            case 'motivo':
                setSelectedMotivos(valor)
                break;
            case 'status':
                setSelectedStatus(valor);
                break;

        }

    }

    useEffect(() => {

        const handleScroll = () => {

            const table = document.querySelector(`.${style.squareTable}`);

            if (table.scrollTop + table.clientHeight >= table.scrollHeight) {

                setLinhas(prev => prev + 100)

            }

        }

        const container = document.querySelector(`.${style.squareTable}`);
        container.addEventListener('scroll', handleScroll);

        return () => container.removeEventListener('scroll', handleScroll);

    }, [])

    const allRegistros = (dados.length).toLocaleString('pt-BR');
    const somaDias = (dados.reduce((acc, obj) => acc + obj.DURACAO, 0)).toLocaleString('pt-BR');

    return (
        <div className={style.bodyContainer} >

            <div className={style.headerContainer} >

                <div className={style.filtersContainer} >

                    <div className={style.filterChild} >
                        <input
                            type='text'
                            placeholder='NOME...'
                            value={selectedName}
                            onChange={(e) => setSelectedName(e.target.value)}
                        />
                    </div>
                    <div className={style.filterChild} >
                        <input
                            type='text'
                            placeholder='MATRICULA...'
                            value={selectedMat}
                            onChange={(e) => setSelectedMat(e.target.value)}
                        />
                    </div>
                    <div className={style.filterChild} >
                        <Select
                            className={style.selectFilter}
                            placeholder='FUNÇÃO...'
                            onChange={(selected) => handleChange(selected, 'funcao')}
                            options={funcoes}
                            components={{ ValueContainer }}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isSearchable
                        />
                    </div>
                    <div className={style.filterChild} >
                        <Select
                            className={style.selectFilter}
                            placeholder='CENTRO CUSTO...'
                            onChange={(selected) => handleChange(selected, 'obra')}
                            options={obras}
                            components={{ ValueContainer }}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isSearchable
                        />
                    </div>

                    <div className={style.filterChild} >
                        <Select
                            className={style.selectFilter}
                            placeholder='MOTIVO...'
                            onChange={(selected) => handleChange(selected, 'motivo')}
                            options={motivos}
                            components={{ ValueContainer }}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isSearchable
                        />
                    </div>
                    <div className={style.filterChild} >
                        <Select
                            className={style.selectFilter}
                            placeholder='SITUAÇÃO...'
                            onChange={(selected) => handleChange(selected, 'status')}
                            options={[
                                { value: 'PREVISTO', label: 'PREVISTO' },
                                { value: 'EM ANDAMENTO', label: 'EM ANDAMENTO' },
                                { value: 'FINALIZADO', label: 'FINALIZADO' }
                            ]}
                            components={{ ValueContainer }}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isSearchable
                        />
                    </div>
                    <div className={style.filterChild} >
                        <input
                            type='date'
                            value={dataInicio}
                            onChange={(e) => setDataInicio(e.target.value)}
                        />
                    </div>
                    <div className={style.filterChild} >
                        <input
                            type='date'
                            value={dataFinal}
                            onChange={(e) => setDataFinal(e.target.value)}
                        />
                    </div>

                </div>

                <div className={style.statsContainer} >
                    <div className={style.statsBox} >
                        <p>TOTAL: {allRegistros}</p>
                        <p>SOMA DE DIAS: {somaDias}</p>
                    </div>
                </div>


            </div>

            <div className={style.squareTable} >

                {loading ?
                    <>
                        <div className={ style.loadingOverlay } >
                            <div className={ style.loadingContainer } >
                                <AiOutlineLoading3Quarters className={ style.loadingIcon } />
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <table>
                            <thead>
                                <tr>
                                    <th
                                        onClick={ () => { onSort() } }
                                    >COLABORADOR</th>
                                    <th>MATRICULA</th>
                                    <th>FUNÇÃO</th>
                                    <th>ENCARREGADO</th>
                                    <th
                                        onClick={ () => { onSortByDate() } }
                                    >DATA INÍCIO</th>
                                    <th>DATA PREV.</th>
                                    <th>DATA FIM</th>
                                    <th>DURAÇÃO</th>
                                    <th>CID</th>
                                    <th>CENTRO CUSTO</th>
                                    <th>CONTRATO</th>
                                    <th>MOTIVO</th>
                                    <th>SITUAÇÃO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dados.slice(0, linhas).map(obj => (
                                    <tr
                                        onClick={() => lineClick(obj.IDENTIFICADOR)}
                                    >
                                        <td>{obj.NOME}</td>
                                        <td>{obj.MATRICULA}</td>
                                        <td>{obj.FUNCAO}</td>
                                        <td>{obj.ENCARREGADO ? obj.ENCARREGADO : '-'}</td>
                                        <td>{obj.DATA_INICIO}</td>
                                        <td>{obj.DATA_PREV ? obj.DATA_PREV : '-'}</td>
                                        <td>{obj.DATA_FIM ? obj.DATA_FIM : '-'}</td>
                                        <td>{obj.DURACAO}</td>
                                        <td>{obj.CID ? obj.CID : '-' }</td>
                                        <td>{obj.CENTRO_CUSTO}</td>
                                        <td>{obj.CONTRATO ? obj.CONTRATO : '-'}</td>
                                        <td>{obj.MOTIVO_AFA}</td>
                                        <td>{obj.STATUS}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                }

            </div>

        </div>
    )

}

export default BodyAfast;