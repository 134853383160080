import style from './AuditBody.module.css';

const AuditBody = ({ dados }) => {

    return(
        <div className={ style.container } >
            <div className={ style.header } ></div>
            <div className={ style.body } >
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>CENTRO CUSTO</th>
                            <th>DATA ÍNICIO</th>
                            <th>DATA FIM</th>
                            <th>STATUS</th>
                            <th>PLANEJAMENTO</th>
                            <th>9001:2015</th>
                            <th>14001:2015</th>
                            <th>45001:2018</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dados.map( obj => (
                                <tr key={ obj.id } >
                                    <td>{ obj.id }</td>
                                    <td>{ obj.centro_custo }</td>
                                    <td>{ obj.data_inicio }</td>
                                    <td>{ obj.data_final }</td>
                                    <td>{ obj.status }</td>
                                    <td>-</td>
                                    <td>{ obj.iso_9001 }/66</td>
                                    <td>{ obj.iso_14001 }/38</td>
                                    <td>{ obj.iso_45001 }/49</td>
                                    <td>{ obj.percent ? obj.percent : '100.00' }%</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )

}

export default AuditBody;