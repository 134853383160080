import styles from './EditRepInput.module.css';

const EditRepInput = ({ title, value, onChange, type, options }) => {

    return (
        <div className={styles.container} >
            <p>{title}</p>

            {

                type === 'select'
                    ? (
                        <select
                            value={ value }
                            onChange={ onChange }
                        >
                            <option value="">SELECIONE...</option>
                            {
                                options.map( value => (
                                    <option key={ value } value={ value } >{ value }</option>
                                ))
                            }
                            
                        </select>
                    ) : (
                        <input
                            type={ type }
                            value={value}
                            onChange={onChange}
                        />
                    )

            }

        </div>
    )

}

export default EditRepInput;