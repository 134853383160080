import axios from 'axios';
import styles from './BenefitsList.module.css';
import Cookies from "js-cookie";
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

const BenefitsList = ({ obraColab }) => {
    const { matricula } = useParams();
    const [statusText, setStatusText] = useState('AUTENTICANDO USUÁRIO');
    const [obras, setObras] = useState([]);
    const [loading, setLoading] = useState(true);
    const [auth, setAuth] = useState(false);
    const [beneficios, setBeneficios] = useState([]);
    const token = Cookies.get('token');
    const [error, setError] = useState('');

    useEffect(() => {
        if (!token) {
            setError('Token de autenticação ausente.');
            setLoading(false);
            return;
        }

        const fetchUserPermissions = async () => {
            try {
                const { data } = await axios.post(
                    'https://sistemaintegrado.palmont.com.br/Painel/API/GetObraPermissions/',
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );

                const permissions = data.PERMISSIONS.map((obj) => ({
                    OBRA: obj.centro_custo
                }));

                setObras(permissions);
            } catch (error) {
                console.log(error);
                setError('Erro ao buscar permissões de usuário.');
            } finally {
                setLoading(false);
            }
        };

        fetchUserPermissions();
    }, [token]);

    useEffect(() => {
        const authObra = obras.some(obj => Object.values(obj).some(value => value.includes(obraColab)));
        setAuth(authObra);

        if (!authObra) {
            setStatusText('USUÁRIO NÃO POSSUÍ NÍVEL DE ACESSO');
            setLoading(false);
            return;
        }

        const getColabBenefits = async () => {
            try {
                setStatusText('BUSCANDO BENEFÍCIOS');
                const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetBenefitsColab/', {
                    matricula: matricula
                });

                setBeneficios(data);
            } catch (error) {
                console.log(error);
                setError('Erro ao buscar benefícios do colaborador.');
            } finally {
                setLoading(false);
                setStatusText('');
            }
        };

        if (obras.length > 0) {
            getColabBenefits();
        }
    }, [obras, obraColab, matricula]);

    const formatMoney = (valor) => {
        if (isNaN(valor)) return '0,00';
        return Number(valor).toFixed(2).replace('.', ',');
    };

    return (
        <div className={styles.container} onClick={ ( e ) => { e.stopPropagation() } } >
            {loading ? (
                <div className={styles.loading}>
                    <h3>{statusText}</h3>
                </div>
            ) : error ? (
                <div className={styles.error}>
                    <p>{error}</p>
                </div>
            ) : auth ? (
                <div>
                    <h3>LISTA DE BENEFÍCIOS</h3>
                    <div className={styles.tableContainer}>
                        <table>
                            <thead>
                                <tr>
                                    <th>BENEFÍCIO</th>
                                    <th>VALOR</th>
                                </tr>
                            </thead>
                            <tbody>
                                {beneficios.map((obj, index) => (
                                    <tr key={index}>
                                        <td>{obj.BENEFICIO}</td>
                                        <td>{formatMoney(parseInt(obj.VALOR))}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className={styles.permissionDenied}>
                    <p>USUÁRIO NÃO POSSUÍ NÍVEL DE ACESSO</p>
                </div>
            )}
        </div>
    );
};

export default BenefitsList;