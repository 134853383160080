import axios from "axios";
import NavBar from "../Componentes/NavBar";
import BodyAfast from "../Componentes/BodyAfast";
import { useCallback, useEffect, useState } from "react";
import Overlay from "../Componentes/Overlay";
import SideMenu from "../Componentes/SideMenu";
import SideMenuBtn from "../Componentes/SideMenuBtn";
import ObsAfast from "../Componentes/ObsAfast";
import LoadingScreen from "../Componentes/LoadingScreen";

import Cookies from 'js-cookie';
import { Navigate } from "react-router-dom";

const Afastamentos = () => {

    const [loadingScreen, setLoadingScreen] = useState(true);
    const [loadingData, setLoadingData] = useState(true);

    const [auth, setAuth] = useState(null);

    const [afastados, setAfastados] = useState([])
    const [afastadosFiltered, setAfastadosFiltered] = useState([])

    const [overlay, setOverlay] = useState(false);
    const [overlayObs, setOverlayObs] = useState(false);
    const [loadingRelat, setLoadingRelat] = useState(false);

    const [userEmail, setUserEmail] = useState('');
    const [idAfast, setIdAfast] = useState('');

    const token = Cookies.get('token');

    useEffect(() => {
        const authenticate = async () => {
            try {
                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/AuthToken/', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log(data)
                setAuth(data);
                setUserEmail(data.USER);

            } catch (error) {

                console.log('Erro ao autenticar:', error);
                setAuth(null);

            } finally {
                setLoadingScreen(false);
            }
        }

        if (token) {
            authenticate();
        } else {
            setLoadingScreen(false);
        }

    }, [token]);

    const getDados = async () => {

        try {

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetAfastados/')

            setAfastados(data)
            setAfastadosFiltered(data)

        } catch (error) {

            console.log(error)

        } finally {

            setLoadingData(false);

        }

    }

    useEffect(() => {

        getDados();

    }, [])

    const handleFilter = useCallback((nameFilter, matFilter, funcFilter, obraFilter, motivoFilter, statusFilter, inicioFilter, finalFilter) => {

        const parseDate = (dateString) => {
            if (!dateString) return null;
            const parts = dateString.split('/');
            if (parts.length !== 3) return null;
            return new Date(parts[2], parts[1] - 1, parts[0]);
        };

        const filtered = afastados.filter(item => {

            const data_inicio = parseDate(item.DATA_INICIO);
            const data_final_item = parseDate(item.DATA_FIM);

            const name_filter = !nameFilter || item.NOME.toUpperCase().includes(nameFilter.toUpperCase());
            const mat_filter = !matFilter || item.MATRICULA.includes(matFilter);

            const func_filter = funcFilter.length === 0 || funcFilter.some(func => item.FUNCAO.includes(func))
            const obra_filter = obraFilter.length === 0 || obraFilter.some(obra => item.CENTRO_CUSTO.includes(obra))
            const motivo_filter = motivoFilter.length === 0 || motivoFilter.some(motivo => (item.MOTIVO_AFA || '').includes(motivo));
            const status_filter = statusFilter.length === 0 || statusFilter.some(status => item.STATUS.includes(status))

            const filter_inicio = !inicioFilter || (data_inicio && new Date(data_inicio) >= new Date(inicioFilter));
            const filter_final = !finalFilter || (data_final_item && new Date(data_final_item) <= new Date(finalFilter));


            return name_filter && mat_filter && filter_inicio && filter_final && func_filter && obra_filter && motivo_filter && status_filter;

        });

        setAfastadosFiltered(filtered);

    }, [afastados]);

    const handleSort = () => {

        const sorted = [...afastados].sort((a, b) => a.NOME.localeCompare(b.NOME))
        const sortedFilter = [...afastadosFiltered].sort((a, b) => a.NOME.localeCompare(b.NOME))

        setAfastados(sorted);
        setAfastadosFiltered(sortedFilter);

    }

    const handleSortByDate = () => {
        const sortedAfastados = [...afastados].sort((a, b) => {

            const dateA = new Date(a.DATA_INICIO.split('/').reverse().join('-'));
            const dateB = new Date(b.DATA_INICIO.split('/').reverse().join('-'));

            return dateB - dateA;

        });

        const sortedAfastadosFiltered = [...afastadosFiltered].sort((a, b) => {

            const dateA = new Date(a.DATA_INICIO.split('/').reverse().join('-'));
            const dateB = new Date(b.DATA_INICIO.split('/').reverse().join('-'));

            return dateB - dateA;

        });

        setAfastados(sortedAfastados);
        setAfastadosFiltered(sortedAfastadosFiltered);

    };

    const funcSet = new Set(afastados.map(obj => obj.FUNCAO).sort());
    const funcoes = Array.from(funcSet).map(obj => ({ label: obj, value: obj }));

    const obrasSet = new Set(afastados.map(obj => obj.CENTRO_CUSTO).sort())
    const obras = Array.from(obrasSet).map(obj => ({ label: obj, value: obj }))

    const motivosSet = new Set(afastados.map(obj => obj.MOTIVO_AFA).sort())
    const motivos = Array.from(motivosSet).map(obj => ({ label: obj, value: obj }))

    const getRelat = async () => {

        if (afastadosFiltered.length < 1000) {

            setLoadingRelat(true)

            try {

                const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GenerateRelat/', afastadosFiltered,
                    {
                        responseType: 'blob'
                    })

                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'relatorio.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            } catch (error) {

                console.log(error)

            } finally {

                setLoadingRelat(false)

            }


        }else{

            alert('Não é possível gerar um relatório com mais de 1.000 linhas. <br/> Se necessário entrar em contato com p-sig@palmont.com.br')

        }

    }

    const showOverlay = () => {

        setOverlay(true)

    }

    const selectAfast = (id) => {

        console.log(id)

        setIdAfast(id);
        setOverlayObs(true);

    }

    const closeObs = () => {

        setOverlayObs(false);
        setIdAfast('');

    }


    if (loadingScreen) {
        return <LoadingScreen />;
    }

    if (!auth) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            <NavBar
                setSideBar={showOverlay}
                title='AFASTAMENTOS'
            />

            <BodyAfast
                dados={afastadosFiltered}
                onFilter={handleFilter}
                funcoes={funcoes}
                obras={obras}
                motivos={motivos}
                lineClick={selectAfast}
                loading={loadingData}
                onSort={handleSort}
                onSortByDate={handleSortByDate}
            />

            <Overlay
                state={overlay}
                changeState={setOverlay}
            >
                <SideMenu>
                    <SideMenuBtn
                        button="GERAR RELATÓRIO"
                        clickFunc={getRelat}
                        isLoading={loadingRelat}
                    />
                </SideMenu>
            </Overlay>

            <Overlay
                state={overlayObs}
            >

                <ObsAfast
                    id={idAfast}
                    user={userEmail}
                    closefunc={closeObs}
                />

            </Overlay>


        </>
    )

}

export default Afastamentos;