import AdmissaoFilter from '../AdmissaoFilter';
import AdmissaoSquare from '../AdmissaoSquare';
import './AdmissaoBody.css'

const AdmissaoBody = ({ tabelaValues, tabelaForFilters, filterTable, openToEdit }) => {

    return(

        <div className='admissaoBody' >

            <AdmissaoFilter
                tabelaValues={ tabelaValues }
                tabelaForFilters={ tabelaForFilters }
                filterTable={ filterTable }
            />

            <AdmissaoSquare 
                tabelaValues={ tabelaValues } 
                openToEdit={ openToEdit }
            />

        </div>

    )

}

export default AdmissaoBody;