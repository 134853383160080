import { formatCPF } from '../../Funcoes/FormatCPF';
import styles from './AdmissaoFilter.module.css';

import { useEffect, useState } from 'react';
import Select, { components } from 'react-select';

const AdmissaoFilter = ({ tabelaValues, filterTable, tabelaForFilters }) => {

    const [funcoes, setFuncoes] = useState([])
    const [obrasOrigem, setObrasOrigem] = useState([])
    const [obrasDest, setObrasDest] = useState([])

    useEffect(()=> {

        const unique_funcoes = Array.from( new Set( tabelaForFilters.map( obj => obj.funcao ) ) ).sort()
        const unique_cc_from = Array.from( new Set( tabelaForFilters.map( obj => obj.cc_origem ) ) ).sort()
        const unique_cc_to = Array.from( new Set( tabelaForFilters.map( obj => obj.cc_destino ) ) ).sort()

        setFuncoes( unique_funcoes )
        setObrasOrigem( unique_cc_from )
        setObrasDest( unique_cc_to )

    },[ tabelaForFilters ])

    const [nomeFilter, setNomeFilter] = useState('');
    const [cpfFilter, setCpfFilter] = useState('');
    const [funcaoFilter, setFuncaoFilter] = useState([]);
    const [ccOrigem, setCcOrigem] = useState([]);
    const [ccDestino, setCcDestino] = useState([]);
    const [motivoFilter, setMotivoFilter] = useState( null );
    const [desistFilter, setDesistFilter] = useState( null );
    const [statusFilter, setStatusFilter] = useState( null );
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const ValueContainer = ({ children, ...props }) => {
        let [values, input] = children;
        if (Array.isArray(values)) {
            const plural = values.length === 1 ? "" : "S";
            values = `${values.length} SELECIONADO${plural}`;
        }
        return (
            <components.ValueContainer {...props}>
                {values}
                {input}
            </components.ValueContainer>
        );
    };

    useEffect(() => {

        filterTable( nomeFilter, cpfFilter, funcaoFilter, ccOrigem, ccDestino, motivoFilter, desistFilter, statusFilter, startDate, endDate )

    }, [ nomeFilter, cpfFilter, funcaoFilter, ccOrigem, ccDestino, motivoFilter, desistFilter, statusFilter, startDate, endDate, filterTable ]);

    //useEffect(() => {
//
    //    filterTable(obraFilter, cpfFilter, nomeFilter, funcaoFilter, statusFilter, desistFilter, startDate, endDate)
//
    //}, [obraFilter, cpfFilter, nomeFilter, funcaoFilter, statusFilter, desistFilter, startDate, endDate]);

    return (
        <div className={styles.container} >
            <div className={styles.filtersContainer} >
                <div className={styles.filterBox} >
                    <input
                        type='text'
                        placeholder='COLABORADOR...'
                        value={ nomeFilter }
                        onChange={ ( e ) => {

                            let { value } = e.target
                            value = value.toUpperCase()

                            setNomeFilter( value )

                        }}
                    />
                </div>
                <div className={styles.filterBox} >
                    <input
                        type='text'
                        placeholder='CPF...'
                        value={ cpfFilter }
                        onChange={ ( e ) => {

                            let { value } = e.target
                            value = value.replace(/\D/g, '');
                            value = formatCPF( value )

                            setCpfFilter( value )

                        }}
                    />
                </div>
                <div className={styles.filterBox} >
                    <Select
                        className={styles.customSelect}
                        components={{ ValueContainer }}
                        placeholder="FUNÇÃO..."
                        options={ funcoes.map( obj => {
                            return { value: obj, label: obj }
                        } ) }
                        isMulti
                        hideSelectedOptions={ false }
                        closeMenuOnSelect={ false }
                        value={ funcaoFilter }
                        onChange={ ( selected ) => { setFuncaoFilter( selected ) } }
                    />
                </div>
                <div className={styles.filterBox} >
                    <Select
                        className={styles.customSelect}
                        components={{ ValueContainer }}
                        placeholder="CC ORIGEM..."
                        options={ obrasOrigem.map( obj => {
                            return { value: obj, label: obj }
                        } ) }
                        isMulti
                        hideSelectedOptions={ false }
                        closeMenuOnSelect={ false }
                        value={ ccOrigem }
                        onChange={ ( selected ) => { setCcOrigem( selected ) } }
                    />
                </div>
                <div className={styles.filterBox} >
                    <Select
                        className={styles.customSelect}
                        components={{ ValueContainer }}
                        placeholder="CC DESTINO..."
                        options={obrasDest.map( obj => {
                            return { value: obj, label: obj }
                        } ) }
                        isMulti
                        hideSelectedOptions={ false }
                        closeMenuOnSelect={ false }
                        value={ ccDestino }
                        onChange={ ( selected ) => { setCcDestino( selected ) } }
                    />
                </div>
                <div className={styles.filterBox} >
                    <Select
                        className={ styles.customSelect }
                        components={{ ValueContainer }}
                        placeholder="MOTIVO..."
                        options={[
                            { value: 'ADMISSAO', label: 'ADMISSAO' },
                            { value: 'TRANSFERENCIA', label: 'TRANSFERENCIA'}
                        ]}
                        isClearable
                        value={ motivoFilter }
                        onChange={ ( selected ) => { setMotivoFilter( selected )}}
                    />
                </div>
                <div className={styles.filterBox} >
                    <Select
                        className={ styles.customSelect }
                        components={{ ValueContainer }}
                        placeholder="DESISTENTES..."
                        options={[
                            { value: 'SIM', label: 'SIM' },
                            { value: 'NAO', label: 'NÃO' }
                        ]}
                        isClearable
                        value={ desistFilter }
                        onChange={ ( selected ) => { setDesistFilter( selected )}}
                    />
                </div>
                <div className={styles.filterBox} >
                    <Select
                        className={ styles.customSelect }
                        components={{ ValueContainer }}
                        placeholder="STATUS..."
                        options={[
                            { value: 'EM ANDAMENTO', label: 'EM ANDAMENTO' },
                            { value: 'FINALIZADO', label: 'FINALIZADO' }
                        ]}
                        isClearable
                        value={ statusFilter }
                        onChange={ ( selected ) => { setStatusFilter( selected )}}
                    />
                </div>
                <div className={styles.filterBox} >
                    <input
                        type='date'
                        value={ startDate }
                        onChange={ ( e ) => { setStartDate( e.target.value )}}
                    />
                </div>
                <div className={styles.filterBox} >
                    <input
                        type='date'
                        value={ endDate }
                        onChange={ ( e ) => { setEndDate( e.target.value )}}
                    />
                </div>
            </div>
            <div className={styles.statsContainer} >
                <div className={ styles.statsBox } >
                    <p>TOTAL: { tabelaValues.length }</p>
                    <p>FINALIZADOS: { tabelaValues.filter( obj => (obj.status === 'ENTROU NO SITE' || obj.desistente === '*' ) ).length }</p>
                    <p>EM ANDAMENTO: { tabelaValues.filter( obj => ( obj.status !== 'ENTROU NO SITE' && obj.desistente === null ) ).length }</p>
                </div>
            </div>
        </div>
    )

    /*return(
        <div className='headerAdmissao' >

            <div className='filterRow1' >

                <select value={ obraFilter } onChange={ ( e ) => {

                    let { value } = e.target;

                    setObraFilter( value );

                } } >

                    <option value={''} >CENTRO CUSTO...</option>

                    {

                        [ ...new Set( tabelaValues.map(obj => obj.centro_custo).filter(centro_custo => centro_custo !== null) ) ].sort().map(centro_custo => (
                            <option value={centro_custo} key={centro_custo}>{centro_custo}</option>
                        ))

                    }

                </select>

                <input type='text' placeholder='CPF:' value={ cpfFilter } onInput={ ( e ) => {

                    let { value } = e.target
                    value = value.replace(/\D/g, '');
                    value = formatCPF( value )

                    setCpfFilter( value )

                } } />

                <input type='text' placeholder='NOME:' value={ nomeFilter } onInput={ ( e ) => {

                    let { value } = e.target
                    value = value.toUpperCase();

                    setNomeFilter( value )

                } } />

            </div>

            <div className='filterRow2' >
                <select value={ funcaoFilter } onChange={ ( e ) => { 

                    let { value } = e.target

                    setFuncaoFilter( value );

                } }  >
                    <option value={''} >FUNCÃO...</option>

                    { 
                        [ ...new Set( tabelaValues.map(obj => obj.vaga_funcao).filter(funcao => funcao !== null) ) ].sort().map(funcao => (
                            <option value={funcao} key={funcao}>{funcao}</option>
                        )) 
                    }

                </select>
                <select value={ statusFilter } onChange={ ( e ) => {

                    let { value } = e.target;

                    setStatusFilter( value );

                } } >
                    <option value={''} >STATUS...</option>

                    { [ ...new Set( tabelaValues.map( obj => obj.last_key ).filter( status => status !== null ) ) ].sort().map( status => (
                        
                        <option value={ status } key={ status } >{ status }</option>

                    ) ) }

                </select>
                <select value={ desistFilter } onChange={ ( e ) => {

                    let { value } = e.target;

                    setDesistFilter( value );

                } } >
                    <option value={''} >DESISTENTE...</option>
                    <option value={'SIM'} >SIM</option>
                    <option value={'NAO'} >NÃO</option>
                </select>

                <div className='filterDatas' >

                    <input type='date' value={ startDate } onChange={ ( e ) => {

                        let { value } = e.target;

                        setStartDate( value );

                    } } />

                    <input type='date' value={ endDate } onChange={ ( e ) => {

                        let { value } = e.target;

                        setEndDate( value );

                    } } />

                </div>
            </div>
        </div>
    )*/

}

export default AdmissaoFilter;