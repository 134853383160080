import style from './BodyContainer.module.css';

import { formatCPF } from '../../Funcoes/FormatCPF/'
import { formatarData } from '../../Funcoes/FormatarData';

const BodyContainer = ({ dados, changeFormulario, showOverlay, changeFormFunc, examList, setExamList, loading }) => {

    console.log( loading )

    const ContainerLoading = () => {

        return(

            <div className={ style.loading } >

                <p>CARREGANDO...</p>

            </div>

        )

    }

    return(
        <div className={ style.bodyContainer } >

            { loading ? <ContainerLoading/> : '' }

            <table>
                <thead>
                    <tr>
                        <th>NOME</th>
                        <th>CPF</th>
                        <th>FUNÇÃO</th>
                        <th>TIPO ASO</th>
                        <th>CLÍNICA</th>
                        <th>OBRA</th>
                        <th>DATA</th>
                        <th>VALIDADE</th>
                        <th>VALOR</th>
                        <th>STATUS</th>
                    </tr>
                </thead>
                <tbody>
                    { dados.map( obj => {

                        let classLinha = '';
                        let statusExam = ''

                        if( obj.status === 'atrasado' ){

                            classLinha = 'atrasado';
                            statusExam = 'VENCIDO';

                        } else if( obj.status === 'vencendo' ){

                            classLinha = 'vencendo';
                            statusExam = "A VENCER"

                        }else if( obj.status === 'pendente'){

                            classLinha = 'vencendo';
                            statusExam = "PENDENTE"

                        }else{

                            statusExam = "VÁLIDO"

                        }

                        return(

                            <tr
                                key={ obj.identifier }
                                className={ style[classLinha] }
                                onClick={ async () => {

                                    let nova_funcao = obj.tipo_aso === '3' ? obj.funcao : ''

                                    changeFormulario({
                                        obra: obj.obra,
                                        tipo_aso: obj.aso,
                                        cod_aso: obj.tipo_aso,
                                        cpf: formatCPF( obj.cpf ),
                                        nome: obj.nome,
                                        funcao: obj.funcao,
                                        data_nasc: obj.data_nasc,
                                        nova_funcao: nova_funcao,
                                        clinica: obj.clinica,
                                        data_aso: obj.data,
                                        identificador: obj.identifier,
                                        data_realizada: obj.data_realizada ? obj.data_realizada : null
                                    })

                                    const examNames = obj.exames.map(exam => exam.EXAME);

                                    const updatedExams = examList.map(exam => ({
                                        ...exam,
                                        check: examNames.includes(exam.exam),
                                        disabled: obj.tipo_aso !== "2" || examNames.includes(exam.exam)
                                    }));
                                
                                    setExamList(updatedExams);

                                    await showOverlay();
                                    changeFormFunc('edit');

                                } }
                            >
                            <td>{ obj.nome }</td>
                            <td>{ formatCPF( obj.cpf ) }</td>
                            <td>{ obj.funcao }</td>
                            <td>{ obj.aso }</td>
                            <td>{ obj.clinica }</td>
                            <td>{ obj.obra }</td>
                            <td>{ formatarData( obj.data ) }</td>
                            <td>{ obj.data_validade ? formatarData( obj.data_validade ) : 'N/A' }</td>
                            <td>{ obj.valor ? 'R$ ' + parseFloat( obj.valor ).toFixed(2).replace( '.', ',' ) : 'R$ 0,    00' }</td>
                            <td>{ statusExam }</td>

                        </tr>

                        )

                    } ) }
                </tbody>
            </table>

        </div>
    )

}

export default BodyContainer;