import style from './RepsBody.module.css';

import Select, { components } from 'react-select';
import { FiPlus } from "react-icons/fi";
import { MdModeEditOutline, MdRemoveRedEye, MdDelete } from "react-icons/md";

const RepsBody = ({ dados, openRep, openView }) => {

    return (
        <div className={style.bodyRep} >

            <div className={style.repHeader} >
                <div className={style.repFilters} >
                    <div className={style.repFilterContainer} >
                        <input
                            type='text'
                            placeholder='REPUBLICA...'
                        />
                    </div>
                    <div className={style.repFilterContainer} >
                        <Select
                            className={style.customSelect}
                            placeholder="CENTRO CUSTO..."

                        />
                    </div>
                    <div className={style.repFilterContainer} >
                        <Select
                            className={style.customSelect}
                            placeholder="CIDADE..."

                        />
                    </div>
                    <div className={style.repFilterContainer} >
                        <Select
                            className={style.customSelect}
                            placeholder="VAGAS..."

                        />
                    </div>
                </div>
                <div className={style.repStats} ></div>
            </div>

            <div className={style.repSquare} >
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>REPUBLICA</th>
                            <th>CENTRO CUSTO</th>
                            <th>LOGRADOURO</th>
                            <th>NÚMERO</th>
                            <th>COMPLEMENTO</th>
                            <th>BAIRRO</th>
                            <th>CIDADE</th>
                            <th>ESTADO</th>
                            <th>QUARTOS</th>
                            <th>VAGAS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dados.map(obj => (

                            <tr className={ style.linhaTabela } key={obj.identifier} >
                                <td className={ style.viewAlojados } >
                                    <MdRemoveRedEye
                                        size={ 16 }
                                        onClick={ () => openView( obj.identifier ) }
                                    />
                                </td>
                                <td className={ style.editAlojamentos } >
                                    <MdModeEditOutline size={ 19 } />
                                </td>
                                <td className={ style.deleteAlojamento } >
                                    <MdDelete size={ 17 } />
                                </td>
                                <td>{obj.apelido_rep}</td>
                                <td>{obj.centro_custo}</td>
                                <td>{obj.logradouro}</td>
                                <td>{obj.numero}</td>
                                <td>{obj.complemento ? obj.complemento : '-'}</td>
                                <td>{obj.bairro}</td>
                                <td>{obj.cidade}</td>
                                <td>{obj.estado}</td>
                                <td>{obj.quartos}</td>
                                <td>{obj.alojados + "/" + obj.vagas}</td>
                            </tr>

                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default RepsBody;