import styles from './ColabField.module.css';

const ColabField = ({ title, type, value, options, onChange }) => {

    return(
        <div className={ styles.container } >
            <div className={ styles.textContainer } >
                <p>{ title }</p>
            </div>
            {
                type === 'select' ? 
                (
                    <select value={ value } onChange={ onChange } >
                        <option value="">SELECIONE...</option>

                            { 
                            
                                options.length > 0 &&

                                    options.map(( option, index ) => {
                                    
                                        const optionValue = typeof option === 'object' ? option.value : option
                                        const optionLabel = typeof option === 'object' ? option.label : option
                                    
                                        return(
                                        
                                            <option key={ index } value={ optionValue } >{ optionLabel }</option>
                                        
                                        )
                                        
                                    } ) }

                    </select>
                ) : (
                    <input
                        type={ type }
                        value={ value }
                        onChange={ onChange }
                    />
                )
            }
        </div>
    )

}

export default ColabField;