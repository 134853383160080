import styles from './ColabInfoChild.module.css';

const ColabInfoChild = ({ title, text }) => {

    return(
        <div className={ styles.container } >

            <div className={ styles.titulo } >
                <p>{ title }:</p>
            </div>

            <div className={ styles.valor } >
                <input type="text" value={ text } readOnly />
            </div>

        </div>
    )

}

export default ColabInfoChild;