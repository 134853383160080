import { useEffect, useState } from 'react';
import styles from './ObsAfast.module.css';

import axios from 'axios';
import { IoMdClose } from "react-icons/io";

const ObsAfast = ({ id, user, closefunc }) => {

    const [ observacao, setObservacao ] = useState('')
    const [ observacoes, setObservacoes ] = useState([])

    const [ saving, setSaving ] = useState(false)

    const handleInfo = async () => {

        try{

            const { data } = await axios.post( 'https://sistemaintegrado.palmont.com.br/Painel/API/GetAfastamentoObs/', {
                id: id
            })

            setObservacoes( data )

        }catch( error ){

            console.log( error )

        }

    }

    useEffect(() => {

        if( id ){

            handleInfo();

        }

    },[id])

    const handleSave = async () => {

        setSaving( true );

        try{

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/SaveObservAfa/',{

                observacao: observacao,
                user: user,
                id: id

            })

            if( data.STATUS === 'SUCESSO' ){

                setObservacao('')
                closefunc();

            }

        }catch( error ){

            console.log( error )

        }finally{

            setSaving( false );

        }

    }

    return(
        <div className={ styles.squareObs } onClick={ ( e ) => { e.stopPropagation() } } >

            <div className={ styles.obsHeader } >
                <IoMdClose
                    className={ styles.closeIcon }
                    onClick={ () => closefunc() }
                />
                <p>OBSERVAÇÕES DO AFASTAMENTO</p>
            </div>

            <textarea
                className={ styles.newObsArea }
                placeholder='DIGITE AQUI...'
                value={ observacao }
                onChange={ ( e ) => { setObservacao( e.target.value ) } }
            />
            <button
                className={ styles.saveBtn }
                onClick={ () => { handleSave() } }
                disabled={ saving }
            >
                { saving ? 'SALVANDO...' : 'SALVAR' }
            </button>

            {
                observacoes.length >= 1 ? (
                    observacoes.map( obj => (
                        <textarea
                            key={ obj.id }
                            readOnly
                            value={ obj.data + ' | ' + obj.user + ' | ' + obj.observacao }
                        />
                    ) )
                ) : (<></>)
            }

        </div>
    )

}

export default ObsAfast;