import { useEffect, useState } from 'react';
import FormComp from '../../FormComp';
import style from './OverlayNewRep.module.css';
import axios from 'axios';

const OverlayNewRep = ({ closeOverlay, obras, updateReps }) => {

    const estadosBrasil = [
        "AC", "AL", "AP", "AM", "BA", "CE", "ES", "GO", "MA", "MT",
        "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS",
        "RO", "RR", "SC", "SP", "SE", "TO"
    ];

    const [ municipios, setMunicipios ] = useState([])

    const [ formValues, setFormValues ] = useState({
        'logradouro': '',
        'numero': '',
        'complemento': '',
        'bairro': '',
        'estado': '',
        'cidade': '',
        'centro_custo': '',
        'republica': ''
    })

    const clearForm = () => {

        setFormValues({
            'logradouro': '',
            'numero': '',
            'complemento': '',
            'bairro': '',
            'estado': '',
            'cidade': '',
            'centro_custo': '',
            'republica': ''
        })

    }

    const handleChange = ( valor, name ) => {

        setFormValues( prev => ({

            ...prev,
            [name] : valor

        }))

    }

    const handleSubmit = async ( e ) => {

        e.preventDefault();

        console.log( formValues )
        
        try{

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/SaveNewRep/', formValues )
            
            clearForm();
            await updateReps();
            closeOverlay();

        }catch( error ){

            console.log( error )

        }

    }

    useEffect(() => {

        handleChange( '', 'cidade' )

        const searchMunicip = async () => {

            try{

                const { data } = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ formValues.estado }/municipios`)
                
                setMunicipios( data.map(( obj ) => (

                    obj.nome.toUpperCase()

                )))

            }catch( error ){

                console.log( error )

            }

        }

        searchMunicip();

    }, [ formValues.estado ])

    return(
        <div className={ style.container } >
            <div className={ style.header } >
                <div
                    className={ style.closeBox }
                    onClick={ () => closeOverlay() }
                ></div>
            </div>
            <div className={ style.body } >
                <form onSubmit={ handleSubmit } >
                    <FormComp
                        title="LOGRADOURO"
                        type="text"
                        placeholder="EX: AVENIDA, RUA, TRAVESSA, PRAÇA"
                        valor={ formValues.logradouro }
                        changeFunc={ ( e ) => {
                            let valor = e.target.value;
                            valor = valor.toUpperCase();

                            handleChange( valor, 'logradouro' )
                        }}
                        required
                    />
                    <FormComp
                        title="NÚMERO"
                        type="text"
                        placeholder="EX: 1234"
                        valor={ formValues.numero }
                        changeFunc={ ( e ) => handleChange( e.target.value, 'numero' ) }
                        required
                    />
                    <FormComp
                        title="COMPLEMENTO"
                        type="text"
                        placeholder="EX: PORTÃO VERDE"
                        valor={ formValues.complemento }
                        changeFunc={ ( e ) => {
                            let valor = e.target.value;
                            valor = valor.toUpperCase();

                            handleChange( valor, 'complemento' )
                        }}
                    />
                    <FormComp
                        title="BAIRRO"
                        type="text"
                        placeholder="DIGITE O BAIRRO"
                        valor={ formValues.bairro }
                        changeFunc={ ( e ) => {
                            let valor = e.target.value;
                            valor = valor.toUpperCase();

                            handleChange( valor, 'bairro' )
                        }}
                        required
                    />
                    <FormComp
                        title="ESTADO"
                        type="select"
                        options={ estadosBrasil }
                        valor={ formValues.estado }
                        changeFunc={ ( e ) => handleChange( e.target.value, 'estado' ) }
                        required
                    />
                    <FormComp
                        title="CIDADE"
                        type="select"
                        options={ municipios }
                        valor={ formValues.cidade }
                        changeFunc={ ( e ) => handleChange( e.target.value, 'cidade' ) }
                        required
                    />
                    <FormComp
                        title="CENTRO DE CUSTO"
                        type="select"
                        options={ obras }
                        valor={ formValues.centro_custo }
                        changeFunc={ ( e ) => handleChange( e.target.value, 'centro_custo' ) }
                        required
                    />
                    <FormComp
                        title="REPUBLICA"
                        type="text"
                        placeholder="EX: CASA 2"
                        valor={ formValues.republica }
                        changeFunc={ ( e ) => {
                            let valor = e.target.value;
                            valor = valor.toUpperCase();

                            handleChange( valor,'republica' )
                        }}
                        required
                    />
                    <FormComp
                        type="submit"
                        valor="SALVAR"
                    />
                </form>
            </div>
        </div>
    )

}

export default OverlayNewRep;