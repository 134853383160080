import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { RouterProvider, createBrowserRouter } from "react-router-dom"

import Home from './Routes/Home'; //Arquivo de CSS com os estilos de todas as tabelas no sistema estão sendo puxados por aqui.
import Login from './Routes/Login';
import ColabInfo from './Routes/ColabInfo';
import Register from './Routes/Register';
import Republicas from './Routes/Republicas';
import FolgaInfo from './Routes/FolgaInfo';
import Pagamento from './Routes/Pagamento';
import Admissao from './Routes/Admissao';
import FolhaPag from './Routes/FolhaPag';
import UsersList from './Routes/UsersList';
import ExamesOcupa from './Routes/ExamesOcupa';
import Afastamentos from './Routes/Afastamentos';
import Recisoes from './Routes/Recisoes';
import NewHome from './Routes/NewHome';
import FolgaCampo from './Routes/FolgaCampo';
import Colab from './Routes/Colab';
import NewReps from './Routes/NewReps';
import AtendimentoAmb from './Routes/AtendimentosAmb';
import AuditoriaInterna from './Routes/AuditoriaInterna';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <NewHome />
      },
      {
        path: "/Home",
        element: <NewHome />
      },
      {
        path: "/Colab/:matricula",
        element: <ColabInfo />
      },
      {
        path: "/Login",
        element: <Login />
      },
      {
        path: "/Register",
        element: <Register />
      },
      {
        path: "/Republicas",
        element: <NewReps />
      },
      {
        path: "/FolgaInfo",
        element: <FolgaInfo />
      },
      {
        path: "/Pagamentos",
        element: <Pagamento />
      },
      {
        path: "/ProcessoAdm",
        element: <Admissao />
      },
      {
        path: "/FolhaPag",
        element: <FolhaPag />
      },
      {
        path: "/UserList",
        element: <UsersList />
      },
      {
        path: "/ExamOcup",
        element: <ExamesOcupa />
      },
      {
        path: "/Afastamentos",
        element: <Afastamentos />
      },
      {
        path: "/Recisoes",
        element: <Recisoes />
      },
      {
        path: "/FolgaCampo",
        element: <FolgaCampo />
      },
      {
        path: "/Colaborador/:matricula",
        element: <Colab />
      },
      {
        path: "/AtendimentoAmb",
        element: <AtendimentoAmb />
      },
      {
        path: "/AuditoriaInterna",
        element: <AuditoriaInterna />
      }
    ]
  },
])

root.render(<React.StrictMode>
    <RouterProvider router={router} />
</React.StrictMode>)