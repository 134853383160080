import axios from "axios";
import AtendAmbBody from "../Componentes/AtendAmbBody";
import NavBar from "../Componentes/NavBar";
import { useEffect, useState } from "react";

const AtendimentoAmb = () => {

    const [ atendimentos, setAtendimentos ] = useState([])
    const [ atendimentosFilter, setAtendimentosFilter ] = useState([])

    const fetchData = async () => {

        try{

            const { data } = await axios.get('http://localhost/Painel/API/GetAtendAmb/');
            
            setAtendimentos(data);
            setAtendimentosFilter(data);

        }catch( error ){

            console.log( error );

        }

    }

    useEffect(() => {

        fetchData();

    }, [])

    return(
        <>
            <NavBar
                title="ATENDIMENTO AMBULATORIAL"
            />
            <AtendAmbBody
                tabelaValues={ atendimentosFilter }
            />
        </>
    )

}

export default AtendimentoAmb;