import { useEffect, useState } from 'react';
import FormComp from '../FormComp';
import styles from './NewFolga.module.css';

import { IoMdClose } from "react-icons/io";
import axios from 'axios';
import { useParams } from 'react-router-dom';

const NewFolga = ({ municipio, estado, obra, user, closeFolga }) => {

    let { matricula } = useParams();

    const [ loading, setLoading ] = useState( true );
    const [ valorPreenchido, setValorPreenchido ] = useState( true );
    const [ formValues, setFormValues ] = useState({
        "valor": "",
        "dias_gozo": "",
        "data_folga": "",
        "dias_prazo": "",
        "obra": "",
        "matricula": matricula,
        "user" : user
    });

    useEffect(() => {

        const getFolgaValues = async () => {

            try{

                const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetFolgaDistance/', {
                    "obra": obra,
                    "municipio": municipio,
                    "estado": estado
                })

                console.log( data )

                if( data.STATUS === 'FAIL' ){

                    setValorPreenchido( false );

                }else{

                    let valores = data.VALUES

                    setFormValues( prev => ({
                        ...prev,
                        ["valor"] : valores.valor_passagem,
                        ["dias_gozo"] : valores.gozo_dias,
                        ["dias_prazo"] : valores.dias_prazo,
                        ["obra"] : obra
                    }))

                }

            }catch( error ){

                console.log( error )
                setValorPreenchido( false );

            }finally{

                setLoading( false );

            }

        }

        if( obra && estado && municipio ){

            getFolgaValues();

        }

    }, [municipio, estado, obra])

    const handleChange = (name, value) => {
        setFormValues((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    useEffect(() => {

        console.log( formValues )

    }, [ formValues ])

    const [ btnValue, setBtnValue ] = useState("SALVAR");

    const saveFolga = async ( e ) => {

        e.preventDefault();
        setBtnValue("SALVANDO...");

        try{

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/SaveNewFolga/', formValues )
            
            if( data.STATUS === "SUCCESS" ){

                alert("Folga salva com sucesso!");
                window.location.reload();

            }

        }catch( error ){

            console.log( error );

        }finally{

            setBtnValue("SALVAR");

        }

    }

    if( loading ){
        return(
            <div className={ styles.loading } >Carregando...</div>
        )
    }

    if( valorPreenchido === false ){

        return(
            <div className={ styles.errorPage } >
                <p>INFORMAÇÕES DA DISTÂNCIA NÃO FORAM PREENCHIDAS</p>
            </div>
        )

    }

    return(
        <div className={ styles.container } onClick={ ( e ) => { e.stopPropagation() } } >
            <div className={ styles.header } >
                <h3>GERAR NOVA FOLGA DE CAMPO</h3>
                <div className={ styles.iconHeader } >
                    <IoMdClose
                        onClick={ () => closeFolga() }
                    />
                </div>
            </div>
            <div className={ styles.body } >
                <form>
                    <FormComp
                        title="VALOR"
                        type="text"
                        readOnly
                        valor={ formValues.valor }
                    />
                    <FormComp
                        title="DIAS GOZO"
                        type="text"
                        readOnly
                        valor={ formValues.dias_gozo }
                    />
                    <FormComp
                        title="DATA"
                        type="date"
                        valor={ formValues.data_folga }
                        changeFunc={ ( e ) => handleChange('data_folga', e.target.value)  }
                    />

                    <button className={ styles.btnSubmit } onClick={ saveFolga } >{ btnValue }</button>

                </form>
            </div>
        </div>
    )
}

export default NewFolga;