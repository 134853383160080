import styles from './AtendAmbBody.module.css';

const AtendAmbBody = ({ tabelaValues }) => {

    return (
        <div className={ styles.bodyAtend } >
            
        </div>
    )

}

export default AtendAmbBody;