import style from './Overlay.module.css';

const Overlay = ({ state, changeState, children, changeContent, transparent }) => {

    const clickFunction = () => {

        if( changeState ){

            changeState( false )

        }

        if( changeContent ){

            changeContent();

        }
    }

    return(
        <div className={`${style.overlay} ${state ? style.show : style.hide} ${ transparent && style.transparentBack } `} onClick={ () => { clickFunction() } } >
            { children }
        </div>
    )

}

export default Overlay;