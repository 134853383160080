import './AdmissaoSquare.css';

import { formatCPF } from '../../Funcoes/FormatCPF';
import { formatarData } from '../../Funcoes/FormatarData';

const AdmissaoSquare = ({ tabelaValues, openToEdit }) => {

    const handleClick = ( value ) => {

        console.log( value )
        openToEdit( value )

    }

    return(
        <div className='admissaoSquare' >

            <table>
                <thead>
                    <tr>
                        <th>ORIGEM CC</th>
                        <th>DESTINO CC</th>
                        <th>NOME</th>
                        <th>CPF</th>
                        <th>FUNÇÃO</th>
                        <th>STATUS</th>
                        <th>DATA</th>
                        <th>MOTIVO</th>
                        <th>DESISTÊNCIA</th>
                    </tr>
                </thead>

                <tbody>
                    { tabelaValues.map( ( obj ) => (
                        <tr 
                            key={ obj.identifier } 
                            className={ obj.ordem_item === 1 ? 'admisAtras' : '' }
                            onClick={ () => handleClick( obj ) }
                        >
                            <td>{ obj.cc_origem ? obj.cc_origem : '-' }</td>
                            <td>{ obj.cc_destino ? obj.cc_destino : '-' }</td>
                            <td>{ obj.nome }</td>
                            <td>{ obj.cpf ? formatCPF( obj.cpf ) : '-' }</td>
                            <td>{ obj.funcao ? obj.funcao : '-' } </td>
                            <td>{ obj.status }</td>
                            <td>{ obj.data ? formatarData( obj.data ) : '-' }</td>
                            <td>{ obj.motivo }</td>
                            <td>{ obj.desistente === '*' ? 'SIM' : '-' } </td>
                        </tr>
                    ) ) }
                </tbody>
            </table>

        </div>
    )

}

export default AdmissaoSquare;