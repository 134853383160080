import styles from './RepsContainer.module.css';
import EditRepInput from '../EditRepInput';

import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import axios from 'axios';


const RepsContainer = ({ repID, repInfo, setRepInfo, closeContainer }) => {

    const [repRooms, setRepRooms] = useState([])

    useEffect(() => {
        const getInfoRep = async () => {
            try {
                const { data } = await axios.post('http://localhost/PAINEL/API/GetRepInfo/', {
                    identifier: repID
                });

                console.log(data);

                setRepInfo((prevState) => ({
                    ...prevState,
                    logradouro: data.logradouro,
                    numero: data.numero,
                    complemento: data.complemento,
                    bairro: data.bairro,
                    CEP: '',
                    cidade: data.cidade,
                    estado: data.estado,
                    centro_custo: data.centro_custo,
                    nome_rep: data.apelido_rep
                }));
            } catch (error) {
                alert(error.message);
            }
        };

        const getRepDetails = async () => {
            try {

                const { data } = await axios.post('http://localhost/Painel/API/GetRepDetails/', {
                    identifier: repID
                });

                console.log(data);
                setRepRooms(data);

            } catch (error) {
                alert(error.message);
            }
        };

        const fetchData = async () => {
            setLoadingInfo(true);
            await Promise.all([getInfoRep(), getRepDetails()]);
            setLoadingInfo(false);
        };

        if (repID) {
            fetchData();
        }

    }, [repID]);

    useEffect(() => {

        console.log(repInfo)

    }, [repInfo])

    const handleChange = (name, value) => {

        setRepInfo(prevValues => ({
            ...prevValues,
            [name]: value.toUpperCase()
        }))

    }

    const [activeObras, setActiveObras] = useState([])

    useEffect(() => {

        let obras = [];

        const getObras = async () => {

            try {

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetObrasAtivas/')

                data.map(obj => obras.push(obj.OBRA))
                setActiveObras(obras)

            } catch (error) {

                console.error(error.message)

            }

        }

        getObras();

    }, [])

    useEffect(() => {

        const { CEP } = repInfo

        if (CEP.length === 8) {

            setRepInfo(prev => ({

                ...prev,
                cidade: 'SÃO PAULO',
                estado: 'SP'

            }))

        } else {

            setRepInfo(prev => ({

                ...prev,
                cidade: '',
                estado: ''

            }))

        }

    }, [repInfo.CEP])

    const [saveInfoBtn, setSaveInfoBtn] = useState('SALVAR')

    const [loadingInfo, setLoadingInfo] = useState(true);

    const [newQuarto, setNewQuarto] = useState(false);
    const [newQuartoForm, setNewQuartoForm] = useState({
        quarto: '',
        vagas: '',
        republica: repID ? repID : ''
    })

    const handleChangeQuarto = (name, value) => {

        setNewQuartoForm(prev => ({
            ...prev,
            [name]: value.toUpperCase()
        }))

    }

    const createNewQuarto = async (e) => {

        e.preventDefault();

        try {

            const { data } = await axios.post('http://localhost/Painel/API/SaveNewQuarto/', newQuartoForm)
            console.log(data)

        } catch (error) {
            alert(error.message)
        }

    }


    if (loadingInfo) {

        return (
            <div className={styles.loadContainer} >
                <AiOutlineLoading3Quarters size={32} />
            </div>
        )

    }

    return (
        <div className={styles.container} >
            <div className={styles.header} >
                <div className={styles.closeContainer} >
                    <MdOutlineClose
                        className={styles.iconClose}
                        size={35}
                        onClick={() => closeContainer()}
                    />
                </div>
            </div>

            <div className={styles.body} >
                <div className={styles.repInfo} >
                    <h3>INFORMAÇÕES DA REPUBLICA</h3>
                    <form>
                        <div className={styles.formContainer} >
                            <EditRepInput
                                title="LOGRADOURO"
                                type="text"
                                value={repInfo.logradouro}
                                onChange={(e) => handleChange('logradouro', e.target.value)}
                            />
                            <EditRepInput
                                title="NÚMERO"
                                type="text"
                                value={repInfo.numero}
                                onChange={(e) => handleChange('numero', e.target.value)}
                            />
                            <EditRepInput
                                title="COMPLEMENTO"
                                type="text"
                                value={repInfo.complemento}
                                onChange={(e) => handleChange('complemento', e.target.value)}
                            />
                            <EditRepInput
                                title="BAIRRO"
                                type="text"
                                value={repInfo.bairro}
                                onChange={(e) => handleChange('bairro', e.target.value)}
                            />
                            <EditRepInput
                                title="CEP"
                                type="text"
                                value={repInfo.CEP}
                                onChange={(e) => handleChange('CEP', e.target.value)}
                            />
                            <EditRepInput
                                title="CIDADE"
                                type="text"
                                value={repInfo.cidade}
                                onChange={(e) => handleChange('cidade', e.target.value)}
                            />
                            <EditRepInput
                                title="ESTADO"
                                type="text"
                                value={repInfo.estado}
                                onChange={(e) => handleChange('estado', e.target.value)}
                            />
                            <EditRepInput
                                title="CENTRO DE CUSTO"
                                type="select"
                                options={activeObras}
                                value={repInfo.centro_custo}
                                onChange={(e) => handleChange('centro_custo', e.target.value)}
                            />
                            <EditRepInput
                                title="NOME REP."
                                type="text"
                                value={repInfo.nome_rep}
                                onChange={(e) => handleChange('nome_rep', e.target.value)}
                            />
                        </div>
                        <button>{saveInfoBtn}</button>
                    </form>
                </div>

                <div className={styles.quartosInfo} >

                    <h3>QUARTOS</h3>

                    {newQuarto ?
                        (
                            <div className={styles.newQuartoForm} >
                                <form>
                                    <EditRepInput
                                        title="QUARTO"
                                        type="text"
                                        value={newQuartoForm.quarto}
                                        onChange={(e) => handleChangeQuarto('quarto', e.target.value)}
                                    />
                                    <EditRepInput
                                        title="VAGAS"
                                        type="number"
                                        value={newQuartoForm.vagas}
                                        onChange={(e) => handleChangeQuarto('vagas', e.target.value)}
                                    />
                                    <div className={styles.btnsNewQuarto} >
                                        <button
                                            className={styles.btnConfirm}
                                            onClick={createNewQuarto}
                                        >SALVAR</button>
                                        <button
                                            className={styles.btnCancel}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setNewQuarto(false);
                                            }}
                                        >CANCELAR</button>
                                    </div>
                                </form>
                            </div>
                        ) : (
                            <>
                                <div className={styles.addNewQuartoBtn} >
                                    <button onClick={() => { setNewQuarto(true) }} >GERAR NOVO QUARTO</button>
                                </div>
                                <div className={styles.containerRooms} >
                                    {
                                        repRooms.map(obj => ((
                                            <div className={styles.repRoom} key={obj.id} >

                                                <h4>{obj.quarto_name} | { obj.alocados }/{ obj.vagas }</h4>

                                                <div className={ styles.tableQuarto } >
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>COLABORADOR</th>
                                                                <th>FUNÇÃO</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        )))
                                    }
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
        </div>
    )

}

export default RepsContainer;