import axios from "axios";
import AuditBody from "../Componentes/AuditInterna/AuditBody";
import NavBar from "../Componentes/NavBar";
import { useEffect, useState } from "react";

const AuditoriaInterna = () => {

    const [ auditorias, setAuditorias ] = useState([])
    const [ filterAuditorias, setFilterAuditorias ] = useState([])

    const fetchAudit = async () => {

        try{

            const { data } = await axios.get('http://localhost/Painel/API/AuditInterna/')
            console.log(data)

            setAuditorias(data)
            setFilterAuditorias(data)

        }catch( error ){

            console.error(error)

        }

    }

    useEffect(() => {

        fetchAudit();

    }, [])

    return(
        <>
            <NavBar
                title="AUDITORIA INTERNA"
            />
            <AuditBody
                dados={ filterAuditorias }
            />
        </>
    )

}

export default AuditoriaInterna;